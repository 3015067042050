/* h1 */
.h1-text {
    font-size: 38px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    font-family: Grotesque-500, serif;
}

.h1-text.tablet {
    font-size: 44px;
}

.h1-text.tablet-smaller {
    font-size: 42px;
}

.h1-text.desktop {
    font-size: 56px;
}

.h1-text.white {
    color: var(--main-white, #FFFFFF);
}


/* h2 */
.h2-text {
    font-family: Grotesque-500, serif;
    font-size: 32px;
    font-weight: 500;
    line-height: 100%; /* 32px */
}

.h2-text.tablet {
    font-size: 38px;
}

.h2-text.desktop {
    font-size: 48px;
}

.h2-text.white {
    color: var(--main-white, #FFFFFF);
}


/* descriptions */
.description-text {
    font-family: ConsolasCustom, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
}

.description-text.desktop {
    font-size: 18px;
}

.description-text.white {
    color: var(--main-white, #FFFFFF);
}

.description-text.black {
    color: var(--main-black, #000000);
}

.description-text.dark {
    color: var(--gray-descriptions, #8A8A8A);
}

.description-text.dark-theme-gray {
    color: var(--dark-theme-descriptions, #AEAEAE);
}



.mobile-menu-text {
    color: var(--main-black, #000);
    font-family: ConsolasCustom, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
}

.mobile-menu-text.white {
    color: var(--main-white, #FFFFFF);
}

.mobile-menu-text.desktop {
    font-size: 18px;
}


.mobile-tag-text {
    font-family: ConsolasCustom, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
}


.main-text {
    font-family: ConsolasCustom, serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
}

.main-text.tablet {
    font-size: 20px;
}

.main-text.desktop {
    font-size: 20px;
}

.main-text.white {
    color: var(--main-white, #FFF);
}

.main-text.centered {
    text-align: center;
}


.mobile-button-text {
    font-family: ConsolasCustom, serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    user-select: none;
}

.mobile-button-text.white {
    color: var(--main-white, #FFFFFF);
}

.mobile-button-text.dark {
    color: var(--main-black, #000000);
}


