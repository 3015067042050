.tag-wrapper {
    display: inline-flex;
    height: 24px;
    padding-top: 2px;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 12px;
    box-sizing: border-box;
}

.tag-wrapper.desktop {
    padding-top: 0;
}

.tag-wrapper.react {
    background-color: var(--tools-tags-react-native-blue, #86E8FF);
}

.tag-wrapper.react_native {
    background-color: var(--tools-tags-react-native-blue, #86E8FF);
}

.tag-wrapper.spring_boot {
    background-color: var(--tools-tags-spring-boot-green, #A5EB7D);
}

.tag-wrapper.cms {
    background-color: var(--tools-tags-cms-yellow, #FFFA74);
}

.tag-wrapper.docker {
    background-color: var(--tools-tags-docker-blue, #90B6F3);
}

.tag-wrapper.highcharts {
    background-color: var(--tools-tags-git-red, #F98B78);
}

.tag-wrapper.ts {
    background-color: var(--tools-tags-postgres-gray, #AFCBE1);
}

.tag-wrapper.java {
    background-color: var(--buttons-orange-on-click, #FE8C39);
}

.tag-wrapper.javafx {
    background-color: var(--buttons-orange-default, #FFB178);
}

.tag-wrapper.junit_5 {
    background-color: var(--tools-tags-git-red, #F98B78);
}

.tag-wrapper.mobx {
    background-color: var(--tools-tags-mobx-orange, #F9B678);
}

.tag-wrapper.mapbox {
    background-color: var(--tools-tags-mapbox-blue, #90B6F3);
}

.tag-wrapper.mui {
    background-color: var(--tools-tags-mui-green, #A5EB7D);
}

.tag-wrapper.google_maps {
    background-color: var(--tools-tags-google-maps, #EEC9FF);
}
