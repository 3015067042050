.project-detailed-wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    max-width: 1264px;
}

.project-detailed-wrapper p {
    padding: 0;
    margin: 0;
}

.project-detailed-wrapper .btns-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
}

.project-detailed-wrapper .info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    align-items: flex-start;
}

.project-detailed-wrapper .info-wrapper .tags-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 8px;
}

.project-detailed-wrapper .images-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.project-detailed-wrapper .buttons-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    margin-top: 24px;
}

.project-detailed-wrapper .maxwidth {
    display: flex;
    width: 100%;
    flex-direction: column;
}

