.page-404-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 64px;
    max-width: 1264px;
}

.page-404-wrapper .svg-and-text-wrapper .text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: flex-start;
}

.page-404-wrapper .svg-and-text-wrapper.desktop {
    flex-direction: row-reverse;
    justify-content: space-between;
}

.page-404-wrapper .svg-and-text-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 64px;
}

.page-404-wrapper .mw616 {
    width: 616px !important;
}
