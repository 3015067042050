.about-page-wrapper {
    display: flex;
    flex-direction: column;
    gap: 64px;
    width: 100%;
}

.about-page-wrapper p {
    padding: 0;
    margin: 0;
}

.about-page-wrapper figure {
    padding: 0;
    margin: 0;
}

.about-page-wrapper.desktop {
    max-width: 1264px;
}

.about-page-wrapper .buttons {
    display: flex;
    width: 100%;
    gap: 12px;
    flex-direction: column;
}

.about-page-wrapper .main-about-wrapper {
    display: flex;
    flex-direction: column-reverse;
    gap: 32px;
    width: 100%;
}

.about-page-wrapper .main-about-wrapper.desktop {
    display: flex;
    flex-direction: row;
    gap: 32px;
    width: 100%;
}

.about-page-wrapper .main-about-wrapper .text-block-wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    flex-shrink: 0;
}

.about-page-wrapper .main-about-wrapper .text-block-wrapper.desktop {
    width: 616px;
}

.about-page-wrapper .main-about-wrapper .text-block-wrapper .text-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.about-page-wrapper .mt13 {
    margin-top: 13px;
}

.about-page-wrapper .main-about-wrapper .avatar-and-code-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 86px;
    width: 100%;
}

.about-page-wrapper .main-about-wrapper .avatar-and-code-wrapper.desktop {
    height: 142px !important;
}

.about-page-wrapper .main-about-wrapper .avatar-and-code-wrapper .code-wrapper {
    position: absolute;
    z-index: 1;
    left: 27px;
    top: 14px;
    display: flex;
    width: 1000px;
    box-sizing: border-box;
    flex-shrink: 0;
    overflow: hidden;
    margin-right: -16px;
}

.about-page-wrapper .main-about-wrapper .avatar-and-code-wrapper .code-wrapper.desktop {
    top: 32px;
    left: 60px;
}
