.desktop-header-link-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.desktop-header-link-wrapper .link-bracket {
    opacity: 0;
}

.desktop-header-link-wrapper .link-bracket.hovered {
    opacity: 1;
}

.desktop-header-link-wrapper.hovered {
    cursor: pointer;
}

.desktop-header-link-wrapper .mint {
    color: var(--buttons-mint-on-click);
}

.desktop-header-link-wrapper .violet {
    color: var(--buttons-violet-on-click);
}

.desktop-header-link-wrapper .yellow {
    color: var(--buttons-yellow-on-click);
}

.desktop-header-link-wrapper .blue {
    color: var(--buttons-blue-on-click);
}

.desktop-header-link-wrapper .orange {
    color: var(--buttons-orange-on-click);
}

.desktop-header-link-wrapper .gray {
    color: var(--gray-descriptions, #8A8A8A);
}



