.services-page-wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    box-sizing: border-box;
    max-width: 1264px;
}

.services-page-wrapper .buttons {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.services-page-wrapper .services {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 32px;
}

.services-page-wrapper .services.desktop {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 32px;
    grid-column-gap: 24px;
    width: 100%;
}
