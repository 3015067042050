.avatar-wrapper {
    width: 86px;
    height: 86px;
    position: relative;
    z-index: 99;
}

.avatar-wrapper.desktop {
    width: 142px !important;
    height: 142px !important;
}

.avatar-wrapper.hovered {
    cursor: pointer;
}

.avatar-wrapper .avatar {
    height: 82px;
    width: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
}

.avatar-wrapper .avatar-shadow {
    height: 82px;
    width: 82px;
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 4px;
    background-color: black;
    z-index: 2;
}

.avatar-wrapper .avatar.desktop {
    width: 138px !important;
    height: 138px !important;
}

.avatar-wrapper .avatar-shadow.desktop {
    width: 138px !important;
    height: 138px !important;
    left: 4px;
}
