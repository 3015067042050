.footer-wrapper {
    display: flex;
    padding: 64px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
    background: var(--gray-light, #EFEFEF);
    box-sizing: border-box;
    position: absolute;
    width: 100%;
}

.footer-wrapper.dark {
    background: var(--dark-theme-light-gray, #2F2F2F);
}

.footer-wrapper .links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.footer-wrapper .bottom-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.footer-wrapper .description-text.white {
    color: var(--dark-theme-descriptions, #AEAEAE);
}

.footer-wrapper .description-text.dark {
    color: var(--gray-descriptions, #8A8A8A);
}

.footer-wrapper .blue-text-hover {
    color: var(--buttons-blue-on-hover);
    cursor: pointer;
}

.footer-wrapper .blue-text-click {
    color: var(--buttons-blue-on-click);
    cursor: pointer;
}

.footer-wrapper .grey-text-hover {
    color: var(--gray-descriptions);
    cursor: pointer;
}

.footer-wrapper .grey-text-click {
    color: #646464;
    cursor: pointer;
}

.footer-wrapper .violet-text-hover {
    color: var(--buttons-violet-on-hover);
    cursor: pointer;
}

.footer-wrapper .violet-text-click {
    color: var(--buttons-violet-on-click);
    cursor: pointer;
}


