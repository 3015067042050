body {
  margin: 0;
    overflow-x: hidden !important;
    transition: background-color .2s ease-in-out;
    -webkit-transition: background-color .2s ease-in-out;
    -moz-transition: background-color .2s ease-in-out;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.dark {
    background-color: var(--dark-theme-bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    color: unset;
}

h1, h2, h3 {
    padding: 0;
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

button,
input[type="button"],
input[type="submit"] {
    appearance: none;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    font: inherit;
    color: inherit;
    cursor: pointer;
    outline: none;
}

.hidden {
    overflow-y: hidden !important;
}


/* fonts */
@font-face {
    font-family: "Grotesque-500";
    src: local("Grotesque-500"),
    url('./assets/custom_fonts/RG-StandardMedium-500.ttf') format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "ConsolasCustom";
    src: local("ConsolasCustom"),
    url('./assets/custom_fonts/CONSOLA.TTF') format("truetype");
    font-weight: normal;
}

