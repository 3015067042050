.project-card-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
}

.project-card-wrapper.hovered {
    cursor: pointer;
}

.project-card-wrapper .tags-wrapper {
    position: absolute;
    top: 16px;
    left: 16px;
    flex-wrap: wrap;
    flex-direction: row;
    display: flex;
    overflow-y: hidden;
    gap: 4px;
    width: calc(100% - 32px);
    z-index: 2;
}

.project-card-wrapper .tags-wrapper.hovered {
    transform: translate(-4px, -4px);
}

.project-card-wrapper .tags-wrapper.clicked {
    transform: translate(-2px, -2px);
}

.project-card-wrapper .project-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 10px;
}

.project-card-wrapper .maxwidth {
    display: flex;
    width: 100%;
    align-items: flex-start;
}

.project-card-wrapper .project-img-preview {
    width: 100%;
    height: auto;
    border-radius: 4px;
    aspect-ratio: 1;
}

.project-card-wrapper .img-gray {
    background-color: var(--dark-theme-light-gray);
}

.project-card-wrapper .img-white {
    background-color: var(--gray-ultralight);
}

.project-img-preview-wrapper {
    display: flex;
    width: 100%;
    border-radius: 4px;
    margin: 0;
    padding: 0;
    aspect-ratio: 1;
}

.project-img-preview-wrapper.hovered-gray {
    box-sizing: border-box;
    box-shadow: 4px 4px 0 0 var(--buttons-gray-default), inset 0 0 0 2px #FFF;
    transform: translate(-4px, -4px);
}

.project-img-preview-wrapper.clicked-gray {
    box-sizing: border-box;
    box-shadow: 2px 2px 0 0 var(--buttons-gray-default), inset 0 0 0 2px #FFF;
    transform: translate(-2px, -2px);
}

.project-img-preview-wrapper.hovered-black {
    box-sizing: border-box;
    box-shadow: 4px 4px 0 0 #000, inset 0 0 0 2px #000;
    transform: translate(-4px, -4px);
}

.project-img-preview-wrapper.clicked-black {
    box-sizing: border-box;
    box-shadow: 2px 2px 0 0 #000, inset 0 0 0 2px #000;
    transform: translate(-2px, -2px);
}
